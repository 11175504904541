import "tailwindcss/tailwind.css";
import { NewsletterModalProvider } from "@components/newsletter-modal";
import ErrorBoundary from "@components/error-boundary";
import type { Session } from "next-auth";
import { createGlobalStyle } from "styled-components";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

type AppProps = {
  Component: React.FC;
  pageProps: {};
};

const GlobalStyle = createGlobalStyle`
  .parity-banner {
    padding: 0.8rem !important;
    font-size: 0.9rem !important;
  }

  .parity-banner.parity-banner-has-logo .parity-banner-logo {
    display: none !important;
  }

  /* image caption on blog */
  .zoom-image + em {
    display: block;
    text-align: center;
    margin-top: -1.4rem;
    font-size: 0.9rem;
  }

  :target.target-with-offset {
    display: block;    
    position: relative;
    top: -80px;
    padding-top: 80px;
    transform: translateY(80px);
  }

  :target.target-with-offset#newsletter-teaser {
    top: -300px;
    padding-top: 300px;
    transform: translateY(300px);

    & > *::before {
      content: " ";
      position: absolute;
      height: 286px;
      width: 86px;
      top: 0;
      left: 40%;
      transform: translateY(-10px) rotate(180deg);
      background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iODkiIGhlaWdodD0iMjg2IiB2aWV3Qm94PSIwIDAgODkgMjg2IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8ZyBjbGlwLXBhdGg9InVybCgjY2xpcDBfMTQwNF83KSI+CjxwYXRoIGQ9Ik0zNC44NTcxIDMuNTkzNDhDMzIuNDIzNCAzLjA4OTUzIDMwLjA0MiA0LjY1Mzg3IDI5LjUzODEgNy4wODc1MkwyMS4zMjU3IDQ2Ljc0NjJDMjAuODIxOCA0OS4xNzk4IDIyLjM4NjEgNTEuNTYxMiAyNC44MTk4IDUyLjA2NTJDMjcuMjUzNCA1Mi41NjkxIDI5LjYzNDggNTEuMDA0OCAzMC4xMzg4IDQ4LjU3MTFMMzcuNDM4NiAxMy4zMTlMNzIuNjkwNyAyMC42MTg5Qzc1LjEyNDQgMjEuMTIyOCA3Ny41MDU4IDE5LjU1ODUgNzguMDA5NyAxNy4xMjQ4Qzc4LjUxMzcgMTQuNjkxMiA3Ni45NDk0IDEyLjMwOTggNzQuNTE1NyAxMS44MDU4TDM0Ljg1NzEgMy41OTM0OFpNMTEuMzAyNiAyODMuOTk2Qzc0LjA5MjIgMjEzLjYxIDg1LjgxMDYgMTUxLjIzIDc4Ljk2MiAxMDIuNDk4QzcyLjE1MzIgNTQuMDQ5NyA0Ny4wMzQ5IDE5LjczMTIgMzcuNzA1NyA1LjUyOTM1TDMwLjE4MzUgMTAuNDcwN0MzOS42ODQzIDI0LjkzMzggNjMuNTY1OSA1Ny42MTUzIDcwLjA0OTYgMTAzLjc1MUM3Ni40OTM1IDE0OS42MDMgNjUuNzk2OSAyMDkuMzkgNC41ODY1OCAyNzguMDA0TDExLjMwMjYgMjgzLjk5NloiIGZpbGw9IiNGRjAwMDAiLz4KPC9nPgo8ZGVmcz4KPGNsaXBQYXRoIGlkPSJjbGlwMF8xNDA0XzciPgo8cmVjdCB3aWR0aD0iODkiIGhlaWdodD0iMjg2IiBmaWxsPSJ3aGl0ZSIvPgo8L2NsaXBQYXRoPgo8L2RlZnM+Cjwvc3ZnPgo=");
    }
  }
`;

const queryClient = new QueryClient();

const App: React.FC<AppProps> = ({ Component, pageProps }) => {
  const { ...otherPageProps } = pageProps;

  return (
    <ErrorBoundary>
      <QueryClientProvider client={queryClient}>
        <NewsletterModalProvider>
          <GlobalStyle />
          <Component {...pageProps} />
        </NewsletterModalProvider>
      </QueryClientProvider>
    </ErrorBoundary>
  );
};

export default App;
