import React from "react";
import type { ErrorInfo } from "react";

export default class ErrorBoundary extends React.Component<
  any,
  { error: Error | null }
> {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  constructor(props: any) {
    super(props);
    this.state = { error: null };
  }

  static getDerivedStateFromError(error: Error): { error: Error | null } {
    return { error };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    console.log("error", error, errorInfo);
  }

  render(): React.ReactNode {
    if (this.state.error) {
      return <h1>An error occured</h1>;
    }
    return this.props.children;
  }
}
